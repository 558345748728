<template>
    <!--ѡȡ����-->
    <section>
        <el-dialog :visible.sync="dlgVisible" v-model="dlgVisible" width="75%" center append-to-body>
            <div id="departtree">
                <el-tree :load="loadDepartNode" :props="DepartProps" @node-click="DepartClick" lazy v-if="datatree" highlight-current :render-content="renderContent" :data="departsearchdata"></el-tree>
            </div>

            <el-input placeholder="����ؼ��ֽ��й���"
                      v-model="filterText">
            </el-input>
            <el-tree class="filter-tree"
                     :data="data"
                     :props="defaultProps"
                     default-expand-all
                     :filter-node-method="filterNode"
                     ref="tree">
            </el-tree>
        </el-dialog>
        <!--<el-form :inline="true" size="mini" style="padding-left:10px;margin-bottom:-15px">
            <el-form-item label="��������:">
                <el-input v-model="departSearch" placeholder="�����벿������" size="mini" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" plain @click="departFind">��ѯ</el-button>
            </el-form-item>
        </el-form>-->
        
    </section>
</template>

<script>
    export default {
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },

        methods: {
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            }
        },

        data() {
            return {
                dlgVisible: false,
                filterText: '', // �����ֶ�
                data: [{
                    id: 1,
                    label: 'һ�� 1',
                    children: []
                }]
            }
            
        }
    }
</script>

<style scoped>
</style>