<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col v-if="buttonList.length > 0" :span="24">
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="用户名:">
            <el-input
              v-model="selectForm.userName"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="试用岗位:">
            <el-input
              v-model="selectForm.postName"
              placeholder="试用岗位"
            ></el-input>
          </el-form-item>
          <el-form-item label="试用日期:">
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                v-model="selectForm.startDate"
                style="width: 100%"
              ></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
                v-model="selectForm.endDate"
                style="width: 100%"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="工资类型:">
            <el-input
              v-model="selectForm.salaryType"
              placeholder="工资类型"
            ></el-input>
          </el-form-item>
          <el-form-item label="职称待遇:">
            <el-input
              v-model="selectForm.positionSal"
              placeholder="职称待遇"
            ></el-input>
          </el-form-item>
          <el-form-item label="试用期:">
            <el-input
              v-model="selectForm.proExplain"
              placeholder="试用期"
            ></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
      
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        class="new_table"
      >
        <el-table-column prop="UserInfoUserName" label="姓名">
        </el-table-column>
        <!-- <el-table-column label="应聘日期" prop="UserBaseInfoApplyDate" :formatter="formatStartTime"></el-table-column> -->
        <!-- <el-table-column label="试用公司" prop="Factory"></el-table-column>
           
            <el-table-column prop = "Dpt"    label = "试用部门">
            </el-table-column> -->
        <el-table-column prop="Post" label="岗位名称"> </el-table-column>
        <el-table-column
          label="开始时间"
          prop="StartDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="试用期(月)"
          prop="ProbationExplain"
        ></el-table-column>
        <el-table-column label="薪资类型" prop="SalaryType"></el-table-column>
        <el-table-column
          label="职务待遇"
          prop="PostSalaryName"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      title="转正"
      :visible.sync="permissionVisible"
      v-model="permissionVisible"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        :rules="postRules"
        ref="PermissionForm"
        :model="PermissionForm"
        class="demo-form-inline"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post">
              <el-input
                type="text"
                :disabled="true"
                v-model="PermissionForm.Post"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="伙食日单价:" prop="EateDaySalary">
              <el-select
                v-model="PermissionForm.EateDaySalary"
                placeholder="伙食日单价"
                style="“width: 100%”"
              >
                <el-option
                  v-for="item in EateSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item prop="StartDate" label="转正日期:">
              <el-date-picker
                type="date"
                v-model="PermissionForm.StartDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                style="“width: 100%”"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="转正工资执行时间:" prop="salaryDate">
              <el-date-picker
                type="month"
                v-model="PermissionForm.salaryDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                style="“width: 100%”"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="是否有年功:"
              prop="IsYear"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="PermissionForm.IsYear"
                placeholder="是否有年功"
                style="width: 100%"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
                <el-option label="超龄年功" value="超龄年功"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
              <el-input
                type="text"
                v-model="PermissionForm.OverAgeUserAvailable"
                @blur="inputMoney('OverAgeUserAvailable')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="日保健费:">
              <el-select
                v-model="PermissionForm.CareDaySalary"
                placeholder="日保健费"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CareDaySalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="驻外补贴:">
              <el-select
                v-model="PermissionForm.OutsideSalary"
                placeholder="驻外补贴"
                style="width: 100%"
              >
                <el-option
                  v-for="item in OutsideSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
              <el-input
                type="text"
                v-model="PermissionForm.OndutyCareSalary"
                @blur="inputMoney('OndutyCareSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工人系数:" prop="WorkerMod">
              <el-input
                type="text"
                v-model="PermissionForm.WorkerMod"
                @blur="inputMoney('WorkerMod')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="租房补贴:" prop="HouseSalary">
              <el-input
                type="text"
                v-model="PermissionForm.HouseSalary"
                @blur="inputMoney('HouseSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绩效工资:" prop="PerfSalary">
              <el-input
                type="text"
                v-model="PermissionForm.PerfSalary"
                @blur="inputMoney('PerfSalary')"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="特岗补贴:">
              <el-input
                v-model="PermissionForm.SpecialSalary"
                @blur="inputMoney('SpecialSalary')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="转正待遇:">
              <el-input
                v-model="PermissionForm.PositiveExplain"
                @blur="inputMoney('PositiveExplain')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车辆补贴:">
              <el-input
                v-model="PermissionForm.BusMoney"
                @blur="inputMoney('BusMoney')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="岗位工资:" prop="WorkSalary">
              <el-input
                v-model="PermissionForm.WorkSalary"
                @blur="inputMoney('WorkSalary')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="爱心基金:">
              <el-input
                v-model="PermissionForm.LoveMoney"
                @blur="inputMoney('LoveMoney')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="日工资:">
              <el-input
                v-model="PermissionForm.DaySalary"
                @blur="inputMoney('DaySalary')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行:">
              <el-input v-model="PermissionForm.BankCardName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行卡号:">
              <el-input v-model="PermissionForm.BankCardCD"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
      <el-form-item  label="开户行:">
			  <el-input  v-model="PermissionForm.BankAddress"></el-input>
	</el-form-item>
   </el-col>		   -->
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="薪资类型:" prop="SalaryType">
              <el-select
                style="width: 100%"
                v-model="PermissionForm.SalaryType"
                @change="SalaryTypeModel($event)"
                placeholder="薪资类型"
              >
                <el-option
                  v-for="item in SalaryTypeLists"
                  :key="item.Id"
                  :label="item.Type"
                  :value="item.Type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="职位待遇:" prop="PostSalaryName">
              <el-select
                style="width: 100%"
                v-model="PermissionForm.PostSalaryName"
                @change="PositionModel($event)"
                placeholder="职位待遇"
              >
                <el-option
                  v-for="item in PositionLists"
                  :key="item.Id"
                  :label="item.LeaveName"
                  :value="item.LeaveName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
         <el-row>
          <el-col :span="24">
            <el-form-item
              label="转正考勤组"
             
              prop="AttendGroupName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
            <el-input
                type="text"
                :disabled="true"
                v-model="PermissionForm.AttendGroupName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="selectAddress"
                ></el-button>
              </el-input>
           
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files
          :files="PermissionForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
        ></upload-files>
        <el-row>
          <el-col :span="24" style="text-align: center">
          
              <el-button
                type="primary"
                v-on:click="onPermissionSubmit('PermissionForm')"
                >提交</el-button
              >
           
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoosejob
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoosejob>
    </el-dialog>
    <el-dialog
      title="查看"
      :visible.sync="permissionShowVisible"
      v-model="permissionShowVisible"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="100px"
        ref="PermissionShowForm"
        :model="PermissionShowForm"
        class="demo-form-inline"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="岗位:" prop="Post">
              <el-input
                type="text"
                :disabled="true"
                v-model="PermissionShowForm.Post"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="伙食日单价:" prop="EateDaySalary">
              <el-select
                v-model="PermissionShowForm.EateDaySalary"
                :disabled="true"
                placeholder="伙食日单价"
                style="width: 100%"
              >
                <el-option
                  v-for="item in EateSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item prop="StartDate" label="开始日期:">
              <el-date-picker
                type="date"
                v-model="PermissionShowForm.StartDate"
                value-format="yyyy-MM-dd"
                :disabled="true"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="试用期:" prop="ProbationExplain">
              <el-select
                v-model="PermissionShowForm.ProbationExplain"
                placeholder="试用期"
                @change="ProbationExplainModel($event)"
                :disabled="true"
                style="width: 100%"
              >
                <el-option
                  v-for="item in ProbationExplainLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="是否有年功:"
              prop="IsYear"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="PermissionShowForm.IsYear"
                placeholder="是否有年功"
                style="width: 100%"
                :disabled="true"
              >
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
                <el-option label="超龄年功" value="超龄年功"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="超龄年功工资:" prop="OverAgeUserAvailable">
              <el-input
                type="text"
                v-model="PermissionShowForm.OverAgeUserAvailable"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="日保健费:">
              <el-select
                v-model="PermissionShowForm.CareDaySalary"
                placeholder="日保健费"
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in CareDaySalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="驻外补贴:">
              <el-select
                v-model="PermissionShowForm.OutsideSalary"
                placeholder="驻外补贴"
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in OutsideSalaryLists"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="值班日保健费:" prop="OndutyCareSalary">
              <el-input
                type="text"
                v-model="PermissionShowForm.OndutyCareSalary"
                @blur="inputMoney('OndutyCareSalary')"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工人系数:" prop="WorkerMod">
              <el-input
                type="text"
                v-model="PermissionShowForm.WorkerMod"
                @blur="inputMoney('WorkerMod')"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="租房补贴:" prop="HouseSalary">
              <el-input
                type="text"
                v-model="PermissionShowForm.HouseSalary"
                @blur="inputMoney('HouseSalary')"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绩效工资:" prop="PerfSalary">
              <el-input
                type="text"
                v-model="PermissionShowForm.PerfSalary"
                @blur="inputMoney('PerfSalary')"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="特岗补贴:">
              <el-input
                v-model="PermissionShowForm.SpecialSalary"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="转正待遇:">
              <el-input
                v-model="PermissionShowForm.PositiveExplain"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="车辆补贴:">
              <el-input
                v-model="PermissionShowForm.BusMoney"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="岗位工资:" prop="WorkSalary">
              <el-input
                v-model="PermissionShowForm.WorkSalary"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="爱心基金:">
              <el-input
                v-model="PermissionShowForm.LoveMoney"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="日工资:">
              <el-input
                v-model="PermissionShowForm.DaySalary"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="银行:">
              <el-input
                v-model="PermissionShowForm.BankCardName"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
      <el-form-item  label="开户行:">
			  <el-input  v-model="PermissionShowForm.BankAddress"></el-input>
	</el-form-item>
   </el-col>		   -->
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="银行卡号:">
              <el-input
                v-model="PermissionShowForm.BankCardCD"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="结束日期:">
              <el-date-picker
                type="date"
                :disabled="true"
                v-model="PermissionShowForm.EndDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="薪资类型:" prop="SalaryType">
              <el-select
                v-model="PermissionShowForm.SalaryType"
                @change="SalaryTypeModel($event)"
                placeholder="薪资类型"
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in SalaryTypeLists"
                  :key="item.Id"
                  :label="item.Type"
                  :value="item.Type"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="职位待遇:" prop="PostSalaryName">
              <el-select
                v-model="PermissionShowForm.PostSalaryName"
                @change="PositionModel($event)"
                placeholder="职位待遇"
                style="width: 100%"
                :disabled="true"
              >
                <el-option
                  v-for="item in PositionLists"
                  :key="item.Id"
                  :label="item.LeaveName"
                  :value="item.LeaveName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注:" prop="Remark">
              <el-input
                type="textarea"
                v-model="PermissionShowForm.Remark"
                :disabled="true"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="是否通过:"
              prop="State"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="PermissionShowForm.State"
                placeholder="请选择"
                style="“width: 100%”"
              >
                <el-option label="试用通过" value="试用通过"></el-option>
                <el-option label="试用未通过" value="试用未通过"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files
          :files="PermissionShowForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
        ></upload-files>
        <el-row v-if="submitPlay">
          <el-col :span="24" style="text-align: center">
        
              <el-button
                type="primary"
                v-on:click="PermissionSubmit('PermissionShowForm')"
                >提交</el-button
              >
        
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
      <el-dialog
      :visible.sync="addressVisible"
      v-model="addressVisible"
      width="75%"
      title="考勤地点"
      center
      append-to-body
    >
      <el-table
        ref="multipleTable1"
        :data="AreaData"
       highlight-current-row
       max-height="300"
        style="width: 100%"
        @current-change="handleSelectionChange1"
      >
        <el-table-column prop="Name" label="考勤组名称"> </el-table-column>
        <!-- <el-table-column label="类型" prop="Type"></el-table-column> -->
      </el-table>
      <div style="margin-top: 20px">
        <el-button v-on:click="areaSelection">确认选择</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  getProbationPageList,
  getInfoList,
  GetUserDis,
  AddPositive,
  getPositiveDetail,
  ProbationUpdateInfo,
  GetDepartmentOrg,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UserChoosejob from "../../components/UserChoosejob";
    import UploadFiles from "../../components/UploadFiles";
    import DptTool from "../../components/Org/DptTool"; // 选取部门控件
export default {
        components: { Toolbar, UserChoosejob, UploadFiles, DptTool},
  data() {
    return {
      key: 0,
      loading: true,
      submitPlay: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      searchVal: "",
      filters: {
        name: "",
      },
      multipleSelection1:[],
      AreaData:[],
      addressVisible: false,
      selectForm: {
        postName: "",
        startDate: "",
        endDate: "",
        proExplain: "",
        salaryType: "",
        positionSal: "",
        userName: "",
      },
      PermissionShowForm: [],
      addUserVisible: false,
      PositionLists: [],
      SalaryTypeLists: [],
      choosedusers: [],
      buttonListmsg: "",
      interviewVisible: false,
      userDetailVisible: false,
      users: [],
      buttonList: [],
      permissionVisible: false,
      permissionShowVisible: false,
      PermissionForm: {
        userId: "",
        Post: "",
        PostId: "",
        StartDate: "",
        SpecialSalary: "0",
        EateDaySalary: "",
        CareDaySalary: "0",
        OutsideSalary: "0",
        ProbationExplain: "",
        PositiveExplain: "",
        BusMoney: "",
        WorkSalary: "",
        FundsDeduct: "",
        DaySalary: "",
        BankCardName: "",
        BankAddress: "",
        BankCardCD: "",
        PositionId: "",
        PostSalaryName: "",
        SalaryType: "",
        SalaryTypeId: "",
        salaryDate: "",
        probationId: "",
        FileArry: [],
         AttendGroupName:"",
      },
      EateSalaryLists: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "30",
          label: "30",
          },
          {
              value: "45",
              label: "45",
          },
          {
              value: "60",
              label: "60",
          },
      ],
      CareDaySalaryLists: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
      ],
      OutsideSalaryLists: [
        {
          value: "300",
          label: "300",
        },
        {
          value: "500",
          label: "500",
        },
      ],
      ProbationExplainLists: [
        {
          value: "0",
          label: "无",
        },
        {
          value: "7",
          label: "7天",
        },
        {
          value: "1",
          label: "1个月",
        },
        {
          value: "2",
          label: "2个月",
        },
        {
          value: "3",
          label: "3个月",
        },
        {
          value: "5",
          label: "5个月",
        },
      ],
      postRules: {
        Post: [{ required: true, message: "请选择岗位", trigger: "blur" }],
        EateDaySalary: [
          { required: true, message: "请填写伙食日单价", trigger: "blur" },
        ],
        WorkSalary: [
          { required: true, message: "请填写岗位工资", trigger: "blur" },
        ],
        StartDate: [
          { required: true, message: "请选择开始时间", trigger: "blur" },
        ],
        PostSalaryName: [
          { required: true, message: "请选择职务待遇", trigger: "blur" },
        ],
        SalaryType: [
          { required: true, message: "请选择工资类型", trigger: "blur" },
        ],
        salaryDate: [
          {
            required: true,
            message: "请选择转正工资执行日期",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
          handleSelectionChange1(val) {
          console.log(val)
      this.multipleSelection1 = val;
    },
      areaSelection() {
        console.log( this.multipleSelection1)
        this.PermissionForm.AttendGroupName = this.multipleSelection1.Name
          this.PermissionForm.AttendGroupId = this.multipleSelection1.Id
      // this.PermissionForm.AttendGroupAreaList = this.multipleSelection1;
      this.addressVisible = false;
     
    },
      selectAddress() {
      let that = this;
      this.$api.GetAllAttendGroup().then((res) => {
        this.AreaData = res.data.response;
        this.addressVisible = true;
        
      });
 
        
    
      
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    //获取用户列表
    getProbation(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        postName: this.selectForm.postName,
        startDate: this.selectForm.startDate,
        endDate: this.selectForm.endDate,
        proExplain: this.selectForm.proExplain,
        salaryType: this.selectForm.salaryType,
        positionSal: this.selectForm.positionSal,
        userName: this.selectForm.userName,
      };
      console.log(para);
      getProbationPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.PermissionForm[name])) {
        this.$message({
          message: "请输入数字类型",
          type: "error",
          duration: 5000,
        });
        this.PermissionForm[name] = null;
        return;
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getProbation(false);
    },
    usercallFunction(newdata) {
        this.PermissionForm.Post = newdata.Name;
        this.PermissionForm.Dpt = newdata.DepartmentId;
      this.PermissionForm.DptId = newdata.DepartmentId;
      this.PermissionForm.PostId = newdata.Id;
      this.PermissionForm.JobType = newdata.WorkTypeId;

        GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
        if (res.data.code == 200) {
            if (res.data.data.deptName != null) {
              this.PermissionForm.Factory = res.data.data.deptName;
          } else {
            this.PermissionForm.Factory = "";
          }

            this.PermissionForm.Dpt = res.data.data.queryDeptName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      console.log(this.PermissionForm);
      this.addUserVisible = false;
    },

    chooseUser() {
      this.addUserVisible = true;
    },
    //添加转正
    addPositive() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      (this.PermissionForm = {
        UserName: row.UserInfoUserName,
        userId: row.UserId,
        Dpt: row.Dpt,
        Factory: row.Factory,
        DptId: row.DptId,
        Post: row.Post,
        PostId: row.PostId,
        StartDate: new Date(),
        SpecialSalary: row.SpecialSalary,
        EateDaySalary: row.EateDaySalary,
        CareDaySalary: row.CareDaySalary,
        OutsideSalary: row.OutsideSalary,
        ProbationExplain: row.ProbationExplain,
        PositiveExplain: row.PositiveExplain,
        BusMoney: row.BusMoney,
        WorkSalary: row.WorkSalary,
        LoveMoney: row.LoveMoney,
        DaySalary: row.DaySalary,
        BankCardName: row.BankCardName,
        BankAddress: row.BankAddress,
        BankCardCD: row.BankCardCD,
        PostSalaryId: row.PostSalaryId,
        PostSalaryName: row.PostSalaryName,
        SalaryType: row.SalaryType,
        SalaryTypeId: row.SalaryTypeId,
        salaryDate: "",
        probationId: row.Id,
        FileArry: [],
        IsYear: null,
        OverAgeUserAvailable: 0,
        OndutyCareSalary: 0,
        Remark: "",
        PerfSalary: 0,
        HouseSalary: 0,
        WorkerMod: 0,
        JobType: row.JobType,
         AttendGroupName:"",
      }),
        console.log(this.PermissionForm);
        getInfoList().then((res) => {
          this.SalaryTypeLists = res.data.response;
        });
      GetUserDis().then((res) => {
        this.PositionLists = res.data.response;
      });
      this.permissionVisible = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "试用未通过") {
        this.submitPlay = false;
      } else {
        this.submitPlay = true;
      }

      getPositiveDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.PermissionShowForm = res.data.response;
        this.key += 1;
        this.permissionShowVisible = true;
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }

      this.submitPlay = false;
      getPositiveDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.PermissionShowForm = res.data.response;
        this.key += 1;
        this.permissionShowVisible = true;
      });
    },
    onPermissionSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: "添加中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            let para = Object.assign({}, this.PermissionForm);
            AddPositive(para).then((res) => {
              loading.close();
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.permissionVisible = false;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  duration: 5000,
                });
              }
            });
            this.getProbation();
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    PermissionSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.PermissionShowForm);
            ProbationUpdateInfo(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.permissionShowVisible = false;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  duration: 5000,
                });
              }
            });
            this.getProbation();
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    PositionModel(id) {
      var obj = {};
      obj = this.PositionLists.find((item) => {
        //model就是上面的数据源
        console.log(item);
        return item.LeaveName === id; //筛选出匹配数据
      });
      this.PermissionForm.PostSalaryId = obj.Id;
    },
    SalaryTypeModel(id) {
      var obj = {};
      obj = this.SalaryTypeLists.find((item) => {
        //model就是上面的数据源
        return item.Type === id; //筛选出匹配数据
      });

      this.PermissionForm.SalaryTypeId = obj.Id;
    },
    dealFiles(data) {
      console.log(data.backData);
      this.PermissionForm.FileArry = data.backData;
      // this.fileList = data.backData;
      // console.log('dealFiles-list:' + this.fileList);
    },
  },
  mounted() {
    this.getProbation();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

